// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyB-ZcFUbmQNNAWcE4dGJ5T89OZP6eYLCAk',
  authDomain: 'smouha-club.firebaseapp.com',
  databaseURL:
    'https://smouha-club-default-rtdb.firebaseio.com',
  projectId: 'smouha-club',
  storageBucket: 'smouha-club.appspot.com',
  messagingSenderId: '743241934871',
  appId: '1:743241934871:web:257f71e85a1b0545efd452',
  measurementId: 'G-GX3G90Z3L4',
};

// Cloud Functions
const backendUrl = `https://europe-west3-${firebaseConfig.projectId}.cloudfunctions.net/api`;
const backendUrlAuth = `https://europe-west3-${firebaseConfig.projectId}.cloudfunctions.net/auth`;
const backendUrlIam = `https://europe-west3-${firebaseConfig.projectId}.cloudfunctions.net/iam`;
const backendUrlBranch = `https://europe-west3-${firebaseConfig.projectId}.cloudfunctions.net/branch`;
const backendUrlAdminstration = `https://europe-west3-${firebaseConfig.projectId}.cloudfunctions.net/administration`;
const backendUrlClinic = `https://europe-west3-${firebaseConfig.projectId}.cloudfunctions.net/clinic`;
const backendUrlRestaurants = `https://europe-west3-${firebaseConfig.projectId}.cloudfunctions.net/restaurant`;
const backendUrlAuctions = `https://europe-west3-${firebaseConfig.projectId}.cloudfunctions.net/auction`;
const backendUrCategory = `https://europe-west3-${firebaseConfig.projectId}.cloudfunctions.net/category`;
const backendUrlSponsor = `https://europe-west3-${firebaseConfig.projectId}.cloudfunctions.net/sponsor`;
const backendUrlSocialMedia = `https://europe-west3-${firebaseConfig.projectId}.cloudfunctions.net/socialMedia`;
const backendUrlAds = `https://europe-west3-${firebaseConfig.projectId}.cloudfunctions.net/ads`;
const backendUrlDiscounts = `https://europe-west3-${firebaseConfig.projectId}.cloudfunctions.net/discounts`;
const backendUrlNews = `https://europe-west3-${firebaseConfig.projectId}.cloudfunctions.net/article`;
const backendUrlAlbum = `https://europe-west3-${firebaseConfig.projectId}.cloudfunctions.net/album`;
const backendUrlTelecom = `https://europe-west3-${firebaseConfig.projectId}.cloudfunctions.net/telecom`;
const backendUrlParking = `https://europe-west3-${firebaseConfig.projectId}.cloudfunctions.net/parking`;
const backendUrlLive = `https://europe-west3-${firebaseConfig.projectId}.cloudfunctions.net/live`;
const backendUrlForm = `https://europe-west3-${firebaseConfig.projectId}.cloudfunctions.net/forms`;
const backendUrlKindergarten = `https://europe-west3-${firebaseConfig.projectId}.cloudfunctions.net/kindergarten`;
const backendUrlLibrary = `https://europe-west3-${firebaseConfig.projectId}.cloudfunctions.net/library`;
const backendUrlculturalService = `https://europe-west3-${firebaseConfig.projectId}.cloudfunctions.net/cultural`;
const backendUrlActivity = `https://europe-west3-${firebaseConfig.projectId}.cloudfunctions.net/activity`;
const backendUrlServiceCenter = `https://europe-west3-${firebaseConfig.projectId}.cloudfunctions.net/service-center`;
const backendUrlGeneralAssembly = `https://europe-west3-smouha-club.cloudfunctions.net/generalAssembly`;

// App Engine
// const backendUrl = `<insert app engine url here>`;

export default {
  firebaseConfig,
  backendUrl,
  backendUrlAuth,
  backendUrlIam,
  backendUrlBranch,
  backendUrlAdminstration,
  backendUrlClinic,
  backendUrlRestaurants,
  backendUrlAuctions,
  backendUrCategory,
  backendUrlSponsor,
  backendUrlSocialMedia,
  backendUrlAds,
  backendUrlDiscounts,
  backendUrlNews,
  backendUrlAlbum,
  backendUrlTelecom,
  backendUrlLive,
  backendUrlForm,
  backendUrlKindergarten,
  backendUrlLibrary,
  backendUrlculturalService,
  backendUrlActivity,
  backendUrlServiceCenter,
  backendUrlParking,
  backendUrlGeneralAssembly,
};
