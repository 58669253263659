import Layout from '@/modules/layout/components/layout.vue';
import { i18n } from '@/vueI18n';

const GeneralAssemblyListPage = () =>
  import(
    '@/modules/general-assembly/components/general-assembly-list-page.vue'
  );

const GeneralAssemblyViewPage = () =>
  import(
    '@/modules/general-assembly/components/general-assembly-view-page.vue'
  );

export default [
  {
    path: '/:lang?',
    exact: true,
    component: Layout,
    children: [
      {
        name: 'general-assembly',
        path: 'general-assembly',
        component: GeneralAssemblyListPage,
        // exact: true,
        meta: { auth: false },
        beforeEnter: (to, from, next) => {
          document.title =
            i18n('app.title') +
            ' | ' +
            i18n('menu.generalAssembly');
          next();
        },
      },
      {
        name: 'general-assembly-view',
        path: 'general-assembly/:id',
        component: GeneralAssemblyViewPage,
      },
    ],
  },
];
