import gql from 'graphql-tag';
import graphqlClient from '@/shared/graphql/client-general-assembly';

export class GeneralAssemblyService {
  static async list(branchId, myAction, currentPage) {
    const response = await graphqlClient.query({
      query: gql`
        query LIST(
    $filter: GeneralAssemblyFilterInput, 
    $orderBy: String,
    $pagination: PaginationInput
) {
    generalAssemblyList(
        filter: $filter, orderBy: $orderBy, pagination: $pagination
    ) {
        pagination {
            isFirstPage
            isLastPage
        }
        count
        rows {
            id
            title {
                en
                ar
            }
            description {
                en
                ar
            }
            pdf {
                publicUrl
            }
            status

            createdAt
            createdBy
            updatedAt
            updatedBy
        }
    }
}
      `,
      variables: {
        filter: { status:'ACTIVE' },
        orderBy: 'createdAt',
        pagination: {
          limit: 6,
          sortBy: 'asc',
          action: myAction,
          page: currentPage,
        },
      },
    });
    return response.data.generalAssemblyList;
  }

  static async find(id) {
    const response = await graphqlClient.query({
      query: gql`
        query FIND($id: String!) {
    generalAssemblyFind(id: $id) {
        id
        title {
            en
            ar
        }
        description {
            en
            ar
        }
        pdf {
            publicUrl
        }
        status

        createdAt
        createdBy
        updatedAt
        updatedBy
    }
}
      `,
      variables: {
        id: id,
      },
    });
    return response.data.generalAssemblyFind;
  }
}
