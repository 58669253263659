
import i18n from '@/vueI18n';
import { mapGetters, mapActions } from 'vuex';
// import FsLightbox from "fslightbox-vue";

export default {
  name: 'app-kindergarten-view-page',
  computed: {
    ...mapGetters({
      isMobile: 'layout/isMobile',
      record: 'generalAssembly/view/record',
      loading: 'generalAssembly/view/loading',
    }),
    isRTL() {
      return i18n.locale == 'ar';
    },
    isDark() {
      return this.$q.dark.isActive;
    },
    evenNumbers() {
      if (this.record) {
        return this.record.images
          .map((_, i) => i)
          .filter((i) => i % 3 === 0);
      }
      return [];
    },
    options() {
      return {
        rewind: true,
        gap: '1.25rem',
        perPage: 2,
        breakpoints: {
          640: {
            perPage: 1,
            gap: '1rem',
          },
          480: {
            perPage: 1,
            gap: '1rem',
          },
        },
      };
    },
  },
  data() {
    return {};
  },
  components: {
    // FsLightbox,
  },
  methods: {
    ...mapActions({
      doFind: 'generalAssembly/view/doFind',
    }),
    i18n(key, args) {
      return this.$t(key, args);
    },
    getFirstWordPresenter(description) {
      if (this.isRTL) {
        return this.getFirstWord(description['ar']);
      } else {
        return this.getFirstWord(description['en']);
      }
    },
    getFirstWord(str) {
      let spaceIndex = str.indexOf(' ');
      return spaceIndex === -1
        ? str
        : str.substr(0, spaceIndex);
    },
    getSecondWordPresenter(description) {
      if (this.isRTL) {
        return this.getSecondWord(description['ar']);
      } else {
        return this.getSecondWord(description['en']);
      }
    },
    getSecondWord(str) {
      return str.split(/\s+/).slice(1, 2)?.toString();
    },
    removeFirstTwoWord(description) {
      let str = this.isRTL
        ? description['ar']
        : description['en'];
      str = str.split(/\s+/).slice(2, str.length);
      return str.join(' ');
    },
    namePresenter(value) {
      return this.isRTL ? value['ar'] : value['en'];
    },
  },
  async created() {
    await this.doFind(this.$route.params.id);
    console.log('record', this.record);
  },
};
