
import { mapGetters, mapActions } from 'vuex';
import i18n from '@/vueI18n';
import { setLanguageCode } from '@/i18n';
import Quasar from 'quasar';
import LoginModal from './loginModal.vue';
import branchChanger from './branchChanger.vue';
import mobileMenu from './mobile-menu.vue';
import darkmodeButton from './darkmode.vue';
import smallSocialLinks from './smallSocialLinks.vue';

// import { DockMenu } from "vue-dock-menu";
// import "vue-dock-menu/dist/vue-dock-menu.css";
// const OFFSET = 60;
export default {
  name: 'app-header',
  components: {
    [LoginModal.name]: LoginModal,
    mobileMenu,
    darkmodeButton,
    branchChanger,
    smallSocialLinks,
  },
  data() {
    return {
      showNavbar: true,
      lastScrollPosition: 0,
      scrollValue: 0,
      loginModal: false,
      loggedIn: false,
      fab2: false,

      items: [
        {
          name: 'File',
          menu: [
            { name: 'Open' },
            { name: 'New Window' },
            { name: 'Exit' },
          ],
        },
        {
          name: 'Edit',
          menu: [
            { name: 'Cut' },
            { name: 'Copy' },
            { name: 'Paste' },
          ],
        },
      ],
    };
  },
  // components:{
  //   DockMenu
  // },
  watch: {
    menuWidth(val) {
      console.log('value_width', val);
    },
  },
  computed: {
    ...mapGetters({
      // currentUser: 'auth/currentUser',
      currentLanguageCode: 'layout/currentLanguageCode',
      menuCollapsed: 'layout/menuCollapsed',
      isMobile: 'layout/isMobile',
      is_screen_xs: 'layout/is_screen_xs',
      is_screen_sm: 'layout/is_screen_sm',
      is_screen_md: 'layout/is_screen_md',
      is_screen_lg: 'layout/is_screen_lg',
      is_labtop: 'layout/is_labtop',
      is_screen_ipad_pro: 'layout/is_screen_ipad_pro',
      sports: 'layout/sports',
      socials: 'layout/socials',
    }),
    menuWidth() {
      const width = window.visualViewport.width;
      console.log(width);
      // return 500
      return width;
    },
    isRTL() {
      return this.currentLanguageCode == 'ar';
    },
    mobileBreakpoint() {
      return this.is_screen_sm || this.is_screen_xs;
    },
    tabletBreakpoint() {
      return this.is_screen_lg || this.is_screen_md;
    },
    navbarOptions() {
      let menuOptionsRight = [];
      if (this.mobileBreakpoint) {
        menuOptionsRight = [
          {
            type: 'button',
            text: this.$t('header.login'),
            path: { name: 'coming-soon' },
            iconLeft: `<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0)">
                        <path d="M21.7969 4H7.6031C5.6179 4 4 5.6179 4 7.61185V14.7C4 15.1329 4.35419 15.4871 4.78709 15.4871H16.8863L15.1504 17.223C14.8443 17.5291 14.8443 18.0276 15.1504 18.3381C15.3034 18.4911 15.5046 18.5698 15.7057 18.5698C15.9069 18.5698 16.108 18.4911 16.2611 18.3381L19.3394 15.2597C19.6455 14.9536 19.6455 14.4551 19.3394 14.1447L16.2611 11.0663C15.955 10.7602 15.4565 10.7602 15.146 11.0663C14.8399 11.3724 14.8399 11.8709 15.146 12.1813L16.8863 13.9129H5.57417V7.61185C5.57417 6.48807 6.48369 5.57417 7.6031 5.57417H21.7969C22.9163 5.57417 23.8258 6.48807 23.8258 7.61185V21.7881C23.8258 22.9119 22.9163 23.8258 21.7969 23.8258H7.6031C6.48369 23.8258 5.57417 22.9119 5.57417 21.7881V19.9516C5.57417 19.5187 5.21998 19.1645 4.78709 19.1645C4.35419 19.1645 4 19.5187 4 19.9516V21.7881C4 23.7777 5.6179 25.4 7.6031 25.4H21.7969C23.7821 25.4 25.4 23.7821 25.4 21.7881V7.61185C25.4 5.62227 23.7821 4 21.7969 4Z" fill="#15A955"/>
                        </g>
                        <defs>
                        <clipPath id="clip0">
                        <rect width="21.4" height="21.4" fill="white" transform="translate(4 4)"/>
                        </clipPath>
                        </defs>
                       </svg>`,
            // class: "button-red"
          },
          {
            type: 'button',
            text: this.$t('header.live'),
            path: { name: 'live' },
            iconLeft:
              '<i class="fas fa-video text-StrongRed"></i>',
          },
          {
            isLinkAction: true,
            type: 'button',
            text: this.$t('common.toggle-language'),
            path: {},
            class: 'button-red',
          },
        ];
      } else {
        menuOptionsRight = [];
      }

      return {
        elementId: 'main-navbar',
        isUsingVueRouter: true,
        mobileBreakpoint: 1100,
        brandImagePath: './',
        // brandImage: require("../src/assets/images/lockup-color.png"),
        brandImage: '',
        brandImageAltText: 'brand-image',
        collapseButtonOpenColor: '#FFDD55',
        collapseButtonCloseColor: '#661c23',
        showBrandImageInMobilePopup: true,
        ariaLabelMainNav: 'Main Navigation',
        tooltipAnimationType: 'shift-away',
        tooltipPlacement: 'bottom',
        menuOptionsLeft: [
          {
            type: 'link',
            text: this.$t('menu.administration'),
            arrowColor: '#659CC8',
            path: { name: 'adminstration' },
            iconRight:
              '<i class="fa fa-long-arrow-right fa-fw"></i>',
          },
          {
            type: 'link',
            text: this.$t('menu.sports'),
            arrowColor: '#FFFFFF',
            iconRight:
              '<i class="fa fa-long-arrow-right fa-fw"></i>',
            subMenuOptions: [
              {
                // isLinkAction: true,
                type: 'link',
                text: this.$t('menu.football'),
                path: { name: 'coming-soon' },
                // iconLeft: '<i class="fa fa-star fa-fw"></i>'
              },
              {
                type: 'link',
                text: this.$t('menu.basketball'),
                path: { name: 'coming-soon' },
                arrowColor: '#659CC8',
                iconRight:
                  '<i class="fas fa-long-arrow-right fa-fw"></i>',
                tooltipPlacement: 'left',
                tooltipAnimationType: 'shift-away',
                subMenuOptions: [
                  {
                    type: 'link',
                    text: this.$t('menu.basketball'),
                    path: { name: 'coming-soon' },
                    arrowColor: '#659CC8',
                  },
                ],
              },
              {
                type: 'link',
                text: this.$t('menu.handball'),
                path: { name: 'coming-soon' },
                arrowColor: '#659CC8',
              },
              {
                type: 'link',
                text: this.$t('menu.volleyball'),
                path: { name: 'coming-soon' },
                arrowColor: '#659CC8',
              },
              {
                type: 'link',
                text: this.$t('menu.swimming'),
                path: { name: 'coming-soon' },
                arrowColor: '#659CC8',
              },
              {
                type: 'link',
                text: this.$t('menu.gymnastics'),
                path: { name: 'coming-soon' },
                arrowColor: '#659CC8',
              },
              {
                type: 'link',
                text: this.$t('menu.squash'),
                path: { name: 'coming-soon' },
                arrowColor: '#659CC8',
              },
              {
                type: 'link',
                text: this.$t('menu.tableTennis'),
                path: { name: 'coming-soon' },
                arrowColor: '#659CC8',
              },
              {
                type: 'link',
                text: this.$t('menu.tennis'),
                path: { name: 'coming-soon' },
                arrowColor: '#659CC8',
              },
              {
                type: 'link',
                text: this.$t('menu.taekwondo'),
                path: { name: 'coming-soon' },
                arrowColor: '#659CC8',
              },
              {
                type: 'link',
                text: this.$t('menu.karate'),
                path: { name: 'coming-soon' },
                arrowColor: '#659CC8',
              },
              {
                type: 'link',
                text: this.$t('menu.judo'),
                path: { name: 'coming-soon' },
                arrowColor: '#659CC8',
              },
              {
                type: 'link',
                text: this.$t('menu.athletics'),
                path: { name: 'coming-soon' },
                arrowColor: '#659CC8',
              },
              {
                type: 'link',
                text: this.$t('menu.hockey'),
                path: { name: 'coming-soon' },
                arrowColor: '#659CC8',
              },
              {
                type: 'link',
                text: this.$t('menu.riding'),
                path: { name: 'coming-soon' },
                arrowColor: '#659CC8',
              },
              {
                type: 'link',
                text: this.$t('menu.billiards'),
                path: { name: 'coming-soon' },
                arrowColor: '#659CC8',
              },
              {
                type: 'link',
                text: this.$t('menu.croquet'),
                path: { name: 'coming-soon' },
                arrowColor: '#659CC8',
              },
              {
                type: 'link',
                text: this.$t('menu.chess'),
                path: { name: 'coming-soon' },
                arrowColor: '#659CC8',
              },
            ],
          },
          // {
          //   type: 'link',
          //   text: this.$t('menu.services'),
          //   arrowColor: '#FFFFFF',
          //   iconRight:
          //     '<i class="fa fa-long-arrow-right fa-fw"></i>',
          //   subMenuOptions: [
          //     {
          //       // isLinkAction: true,
          //       type: 'link',
          //       text: this.$t('menu.serviceCenter'),
          //       path: { name: 'coming-soon' },
          //     },
          //     {
          //       type: 'link',
          //       text: this.$t(
          //         'menu.sportsActivitiesReservation',
          //       ),
          //       path: { name: 'coming-soon' },
          //       arrowColor: '#659CC8',
          //     },
          //     {
          //       type: 'link',
          //       text: this.$t('menu.theGym'),
          //       path: { name: 'coming-soon' },
          //       arrowColor: '#659CC8',
          //     },
          //   ],
          // },
          {
            type: 'link',
            text: this.$t('menu.auctions'),
            arrowColor: '#659CC8',
            path: { name: 'auctions' },
            iconRight:
              '<i class="fa fa-long-arrow-right fa-fw"></i>',
          },
          {
            type: 'link',
            text: this.$t('menu.branches'),
            arrowColor: '#659CC8',
            path: { name: 'branch' },
            // path:`/${localStorage.getItem('language')}/branch`,
            iconRight:
              '<i class="fa fa-long-arrow-right fa-fw"></i>',
          },
          // {
          //   type: 'link',
          //   text: this.$t('menu.shop'),
          //   arrowColor: '#659CC8',
          //   path: { name: 'coming-soon' },
          //   iconRight:
          //     '<i class="fa fa-long-arrow-right fa-fw"></i>',
          // },
          {
            type: 'link',
            text: this.$t('menu.news'),
            path: { name: 'news' },
            // path:`/news`,
            iconRight:
              '<i class="fa fa-long-arrow-right fa-fw"></i>',
          },
          {
            type: 'link',
            text: this.$t('menu.media'),
            path: { name: 'media' },
            iconRight:
              '<i class="fa fa-long-arrow-right fa-fw"></i>',
          },
          {
            type: 'link',
            text: this.$t('menu.other'),
            arrowColor: '#FFFFFF',
            iconRight:
              '<i class="fa fa-long-arrow-right fa-fw"></i>',
            subMenuOptions: [
              {
                // isLinkAction: true,
                type: 'link',
                text: this.$t(
                  'menu.otherItems.restaurants',
                ),
                path: { name: 'restaurant' },
              },
              {
                type: 'link',
                text: this.$t('menu.otherItems.clinics'),
                path: { name: 'clinic' },
                arrowColor: '#659CC8',
              },
              {
                type: 'link',
                text: this.$t('menu.otherItems.library'),
                path: { name: 'library' },
                arrowColor: '#659CC8',
              },
              {
                type: 'link',
                text: this.$t(
                  'menu.otherItems.medicalDiscounts',
                ),
                path: { name: 'medical-discounts' },
                arrowColor: '#659CC8',
              },
              {
                type: 'link',
                text: this.$t(
                  'menu.otherItems.kindergarten',
                ),
                path: { name: 'kindergarten' },
                arrowColor: '#659CC8',
              },
              {
                type: 'link',
                text: this.$t(
                  'menu.otherItems.nonAthletes',
                ),
                path: { name: 'non-athletes' },
                arrowColor: '#659CC8',
              },
              {
                type: 'link',
                text: this.$t(
                  'menu.otherItems.generalAssembly',
                ),
                path: { name: 'general-assembly' },
                arrowColor: '#659CC8',
              },
            ],
          },
        ],
        menuOptionsRight: menuOptionsRight,
      };
    },
  },

  async created() {
    console.log(
      'this is socials from header',
      this.socials,
    );
  },
  mounted() {
    // this.lastScrollPosition = window.pageYOffset;
    // window.addEventListener('scroll', this.onScroll);
    // const viewportMeta = document.createElement('meta');
    // viewportMeta.name = 'viewport';
    // viewportMeta.content =
    //   'width=device-width, initial-scale=1';
    // document.head.appendChild(viewportMeta);
  },

  beforeDestroy() {
    // window.removeEventListener('scroll', this.onScroll);
  },

  methods: {
    onScroll() {
      // if (window.pageYOffset < 0) {
      //   return;
      // }
      // if (
      //   Math.abs(
      //     window.pageYOffset - this.lastScrollPosition,
      //   ) < OFFSET
      // ) {
      //   return;
      // }
      // this.showNavbar =
      //   window.pageYOffset < this.lastScrollPosition;
      // this.lastScrollPosition = window.pageYOffset;
    },
    ...mapActions({
      toggleMenu: 'layout/toggleMenu',
      doSignout: 'auth/doSignout',
      changeCurrentLanguageCode:
        'layout/changeCurrentLanguageCode',
      doFetchSocials: 'layout/doFetchSocials',
    }),
    toggleTheme() {
      localStorage.setItem('mode', !this.$q.dark.isActive);
      this.$q.dark.set(!this.$q.dark.isActive);
    },
    goToProfile() {
      this.$router.push('profile');
    },
    goToRoute(name) {
      // debugger
      this.$router.push({
        name,
      });
      // this.$router.push('/ar/branch')
    },
    i18n(key, args) {
      return i18n(key, args);
    },
    handleDropdownCommand(command) {
      if (command === 'doSignout') {
        this.doSignout();
      }

      if (command === 'doEditProfile') {
        this.doEditProfile();
      }
    },
    doEditProfile() {
      return this.$router.push('/auth/edit-profile');
    },
    vnbItemClicked(menuOption) {
      console.log('menuOption', menuOption);
      // this.doToggleLanguage()
      // if (menuOption == 'EN') {
      //   this.doChangeLanguage('en')
      // } else if (menuOption == 'العربية') {
      //   this.doChangeLanguage('ar')
      // }
    },
    doToggleLanguage() {
      let language =
        this.currentLanguageCode == 'ar' ? 'en' : 'ar';
      i18n.locale = language;
      localStorage.setItem('language', language);
      setLanguageCode(language);
      this.changeCurrentLanguageCode(language);

      // const { lang } = this.$route.params;
      // let route = '';
      // if (lang && (lang === 'ar' || lang === 'en')) {
      //   route = `/${language}${this.$route.fullPath.substring(3)}`;
      // } else {
      //   route = `/${language}${this.$route.fullPath}`;
      // }

      if (language === 'ar') {
        import(`quasar/lang/ar`).then((lang) => {
          // eslint-disable-next-line
          Quasar.lang.set(lang.default);
        });
      } else {
        import(`quasar/lang/en-us`).then((lang) => {
          // eslint-disable-next-line
          Quasar.lang.set(lang.default);
        });
      }
      // this.$router.push(route);
    },
  },
};
